import './understanding-utuc.scss';
import './helpful-resources.scss';
import React, { useEffect, useState } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { navigate } from '@reach/router';
import { snakeCase, gotoHash } from '../components/utils';
import FAQSection from '../components/resources/faqSection';
import OrgSection from '../components/resources/orgSection';
import GlossarySection from '../components/resources/glossarySection';
import UTUCVideoSection from '../components/resources/vidSection';
import 'twin.macro';

import BackgroundImage from 'gatsby-background-image';
import cornerImage from '../images/page-header-corner-only.png';

const Doctor = ({ location }) => {
  const { desktop, tablet, mobile } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "people-05-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tablet: file(relativePath: { eq: "people-05-tablet.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobile: file(relativePath: { eq: "people-05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  // Art-Direction Array
  const backgroundArtDirectionStack = [
    mobile.childImageSharp.fluid,
    {
      ...tablet.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
    {
      ...desktop.childImageSharp.fluid,
      media: `(min-width: 1025px)`,
    },
  ];

  const navItemData = [
    'Uncovering UTUC video',
    'Organizations and Tools',
    'FAQs',
    'Glossary',
  ];

  const [activeSection, setActiveSection] = useState(
    `#${snakeCase(navItemData[0])}`,
  );

  const switchSection = section => {
    navigate(`#${section}`);
  };

  const ActiveSection = () => {
    let activeComponent;
    switch (activeSection) {
      case `#${snakeCase(navItemData[0])}`:
        activeComponent = <UTUCVideoSection />;
        break;
      case `#${snakeCase(navItemData[1])}`:
        activeComponent = <OrgSection />;
        break;
      case `#${snakeCase(navItemData[2])}`:
        activeComponent = <FAQSection />;
        break;
      case `#${snakeCase(navItemData[3])}`:
        activeComponent = <GlossarySection />;
        break;
      default:
        activeComponent = <UTUCVideoSection />;
        break;
    }
    return activeComponent;
  };

  useEffect(() => {
    if (location.hash) {
      const hash = gotoHash(
        location.hash,
        navItemData,
        '.page-header__nav_list',
      ); //hash, known hashes, selector, smooth(optional)
      if (hash && activeSection !== hash) {
        setActiveSection(hash);
      }
    }
  }, [location.hash, activeSection, navItemData]);

  return (
    <Layout>
      <SEO
        title="UTUC | Resources"
        keywords={[
          'UTUC',
          'upper tract urothelial cancer',
          'upper tract urothelial carcinoma',
          'talking to a urologist',
          'questions about UTUC',
          'UTUC treatment plan',
          'doctors who treat UTUC',
          'UTUC answers',
          'UTUC FAQ',
          'UTUC frequently asked questions',
          'UTUC resources',
          'UTUC information',
        ]}
        description="Before you and your urologist can choose the right treatment for you, it&rsquo;s important to find out exactly what&rsquo;s going on with your cancer. Get a list of questions to ask and things to remember when you talk to your doctor"
        page="doctor"
      />

      <div className="doctor__container">
        <BackgroundImage
          Tag="section"
          fluid={backgroundArtDirectionStack}
          className="doctor__header page-header"
          aria-label="Helpful resources"
          critical={true}
          fadeIn={false}
          durationFadeIn={0}
        >
          <div className="doctor__header_content">
            <div>
              <h2 className="doctor__header_title page-header__title">
                Helpful resources
              </h2>
              <div className="page-header__content">
                <p tw="mb-6">
                  It’s important to learn as much as possible about UTUC so you
                  can partner with your doctors in every decision. The video
                  below covers a lot of good information!
                </p>
                <p>
                  It’s also crucial to get support when you need it, whether
                  that means educational, emotional, or financial—or just help
                  getting the conversation started with your doctor. See the
                  list of organizations and download a list of questions below
                  that can help.
                </p>
              </div>
            </div>
          </div>
          <div className="page-header__nav_container">
            <img
              className="page-header__nav_corner"
              src={cornerImage}
              alt="presentational corner radius for nav menu"
            />
            <nav
              className="page-header__nav understanding__page_nav"
              aria-label="understanding subpages"
            >
              <ul className="page-header__nav_list">
                {navItemData.map((navItem, index) => {
                  const slug = snakeCase(navItem);
                  return (
                    <li
                      key={`understandingNavItem${index}`}
                      className={`page-header__nav_item ${
                        '#' + slug === activeSection ? 'active' : ''
                      }`}
                    >
                      <button
                        className="page-header__nav_link"
                        onClick={e => {
                          e.preventDefault();
                          switchSection(slug);
                        }}
                      >
                        {navItem}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </BackgroundImage>
        <div className="page-content">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={activeSection}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false);
              }}
              classNames="fade"
            >
              <div
                // className="subsection"
                // aria-label="UTUC video presentation"
                tw="mt-12"
              >
                {<ActiveSection />}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </Layout>
  );
};

export default Doctor;
